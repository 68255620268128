<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">设备租赁清单</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
      @submit="handleSearch" @submit.native.prevent>
      <a-row>
        <a-form-model-item style="float: right; margin-right: 0">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="Reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item label="所属医院">
          <a-select placeholder="请选择" v-model="query.hospital_id">
            <a-select-option :value="0" :key="0">
              全部
            </a-select-option>
            <a-select-option :value="item.hospital_id" v-for="item in hospitals" :key="item.hospital_id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备类型">
          <a-select placeholder="请选择" v-model="query.connect_type" @change="onSelectChange">
            <a-select-option :value="0" :key="0">
              全部
            </a-select-option>
            <a-select-option :value="2" :key="2">
              4G设备
            </a-select-option>
            <a-select-option :value="1" :key="1">
              WiFi设备
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备编号">
          <a-input v-model="query.equipment_number" placeholder="设备编号" />
        </a-form-model-item>
      </a-row>
      <a-row style="margin-top: 20px">
        <a-form-model-item label="设备状态">
          <a-checkbox-group v-model="checkValue" name="checkboxgroup" :options="checkOptions" @change="changeCheck" />
        </a-form-model-item>
      </a-row>
    </a-form-model>
    <a-table class="main-table" :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }" :pagination="{
  current: query.page,
  total: pagetotal,
  showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px" v-viewer>
      <span slot="area" slot-scope="text, record">
        {{ record.hospital_name + '-' + record.department_name }}
      </span>
      <span slot="qr_img" slot-scope="text, record">
        <img :src="record.qr_img" class="img-qr" />
      </span>
      <span slot="use_status" slot-scope="text, record">
        <a-tag :color="record.use_status === 1 ? '#74CE78' : '#757776'">
          {{ record.use_status === 1 ? '租用中' : '未租用' }}
        </a-tag>
      </span>
      <span slot="equipment_status" slot-scope="text, record">
        <a-tag :color="record.equipment_status === 1 ? '#74CE78' : '#757776'">
          {{ record.equipment_status === 1 ? '在线' : '离线' }}
        </a-tag>
      </span>
      <span slot="qr_code" slot-scope="text">
        <img :src="text" class="img-qr" />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="toSet(record)">租用</a>
          <a style="color: #e0352b" @click="toStop(record)">归还</a>
        </a-space>
      </span>
    </a-table>
    <div>
      <a-modal title="设备租用" :visible="visible" ok-text="提交" cancel-text="取消" @ok="submitForm" @cancel="handleCancel">
        <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="Form" :model="form" :rules="Rules">
          <a-form-model-item prop="time" label="租用时间">
            <a-input v-model="form.time">分钟</a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10,
      },
      visible: false,
      form: {},
      hospitals: [],
      Rules: {
        time: [
          {
            required: true,
            message: '请输入时间',
            trigger: 'blur',
          },
        ],
      },
      pagetotal: 0,
      checkValue: [],
      checkOptions: [
        {
          label: '在线',
          value: 1,
        },
        {
          label: '离线',
          value: 2,
        },
        {
          label: '租用中',
          value: 3,
        },
        {
          label: '未租用',
          value: 4,
        },
      ],
      columns: [
        {
          title: '序号',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_number',
        },
        {
          title: '硬件编号',
          dataIndex: 'hardware_number',
        },
        {
          title: '所属医院',
          scopedSlots: {
            customRender: 'area',
          },
        },
        {
          title: '租用',
          scopedSlots: {
            customRender: 'use_status',
          },
        },
        {
          title: '在线状态',
          scopedSlots: {
            customRender: 'equipment_status',
          },
        },
        {
          title: '累计租用次数',
          dataIndex: 'all_rent_count',
        },
        {
          title: '使用总时长',
          dataIndex: 'all_rent_time',
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      data: [],
      selectedRowKeys: [],
    }
  },
  created() {
    this.getList()
    this.getHospitals()
  },
  methods: {
    submitForm() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          this.confirmLoading = true
          this.$post('equipment/equipmentRent', {
            equipment_id: this.equipment_id,
            time: this.form.time,
            type: 1,
          })
            .then((res) => {
              let { code, msg, data } = res
              this.confirmLoading = false
              if (code == 0) {
                this.$message.success(msg, 1.5)
                this.initForm()
                this.getList()
              } else {
                this.$message.error(msg, 1.5)
              }
            })
            .catch((err) => {
              this.confirmLoading = false
            })
        }
      })
    },
    handleCancel() {
      this.initForm()
    },
    initForm() {
      this.visible = false
      this.form = {}
      this.equipment_id = null
    },
    del(id) {
      console.log(id)
      this.$confirm({
        title: '确认',
        content: '确认删除该设备吗',
        onOk: () => {
          this.$post(domain + '/admin/v1/equipment', {
            equipment_id: id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => { },
      })
    },
    toStop(item) {
      this.$post('equipment/equipmentRent', {
        equipment_id: item.equipment_id,
        type: 2,
      })
        .then((res) => {
          let { code, msg, data } = res
          this.confirmLoading = false
          if (code == 0) {
            this.$message.success(msg, 1.5)
          } else {
            this.$message.error(msg, 1.5)
          }
        })
        .catch((err) => {
          this.confirmLoading = false
        })
    },
    getHospitals() {
      this.$post(domain + '/admin/v1/hospital/hospitalSelect', {}).then(
        (res) => {
          if (res.code === 0) {
            this.hospitals = res.data
          } else {
            this.$message.error(res.msg)
          }
        }
      )
    },
    Reset() {
      this.query = {
        page: 1,
        limit: this.query.limit,
      }
      this.getList()
    },
    equipmentOn(id) {
      this.$post(domain + '/admin/v1/equipment/equipment/on', {
        equipment_id: id,
      }).then((res) => {
        if (res.code === 0) {
          console.log(res)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    toSet(item) {
      this.visible = true
      this.equipment_id = item.equipment_id
    },
    toUnBind(item) {
      console.log('我选中了啥', this.selectedRowKeys)
      if (this.selectedRowKeys.length <= 0) {
        this.$message.error('请选中设备进行解绑')
      } else {
        let obj = {
          equipment_ids: this.selectedRowKeys,
        }
        this.$post('equipment/equipmentUnbind', obj)
          .then((res) => {
            let { code, msg, data } = res
            if (code == 0) {
              this.$message.success(msg, 4)
              this.getList()
            } else {
              this.$message.error(msg, 1.5)
            }
          })
          .catch((err) => {
            this.confirmLoading = false
          })
      }
    },
    toChangeList(id) {
      this.$router.push({
        path: '/changeList',
        query: {
          id: id,
        },
      })
    },
    toContrastList(id) {
      this.$router.push({
        path: '/contrastList',
        query: {
          id: id,
        },
      })
    },
    getList() {
      this.$post(domain + '/admin/v1/equipment/equipmentList', this.query).then(
        (res) => {
          if (res.code === 0) {
            this.data = res.data.list
            this.pagetotal = res.data.total
          } else {
            this.$message.error(res.msg)
          }
        }
      )
    },
    listChange(e) {
      this.query.page = e.current
      this.getList()
    },
    handleSearch(query) {
      this.query.page = 1
      this.customer_id = null
      this.getList()
      console.log(query)
    },
    changeCheck(value) {
      this.query.page = 1
      this.query.type = value
      this.getList()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
  },
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>
